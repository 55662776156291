import { connect } from 'react-redux';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import { AppDispatch, RootState } from '@/store';
import * as UserActions from '../../../../actions/UserActions';
import { getCategories, getNav, getWeddingShopCategories } from '../../../../actions/NavActions';

import UnifiedNavV2, { UnifiedNavDispatchProps, UnifiedNavStateProps } from './UnifiedNavV2';
import { getUserContext } from '../../../../selectors/userSelectors';

const mapStateToProps = (state: RootState): UnifiedNavStateProps => ({
  user: getUserContext(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): UnifiedNavDispatchProps => ({
  fetchNav: () => dispatch(getNav()),
  fetchCategories: () => dispatch(getCategories()),
  fetchWeddingShopCategories: () => dispatch(getWeddingShopCategories()),
  fetchUserContext: () => dispatch(UserActions.getUserContext()),
});

const connector = connect(mapStateToProps, mapDispatchToProps)(UnifiedNavV2);
export default connector;

export { NAV_DATA };
