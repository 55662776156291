import { publishFeatureFlagOverrides } from '@zola-helpers/client/dist/es/featureFlags';
import { FeatureFlagsProductType } from '@zola-helpers/client/dist/es/constants/navConstants';
import { getBoutiqueNavItems } from '@zola-helpers/client/dist/es/nav/boutiqueNavData';
import {
  getRegistryNavItems,
  getRegistryQuickLinks,
  getRegistrySecondaryNavItems,
} from '@zola-helpers/client/dist/es/nav/registryNavData';
import experimentFlags, { HOLIDAY_SHOPS } from '../../../../util/experimentFlags';
import { FLAGS } from '../../../../util/featureFlags';

const initNavData = ({
  user,
  showPromo,
  fetchUserContext,
  fetchCategories,
  fetchWeddingShopCategories,
  activePrimaryLink,
  activeSecondaryLink,
  activeTertiaryLink,
  disablePrimaryNavCollapse,
  disableSecondary,
  isWeddingBoutiquePage,
}) => {
  const fetchCategoriesAction = isWeddingBoutiquePage
    ? fetchWeddingShopCategories
    : fetchCategories;

  return Promise.all([!user ? fetchUserContext() : Promise.resolve(user), fetchCategoriesAction()])
    .then(([userContextResponse, categoriesResponse]) => {
      let userContext;
      let categories;
      let secondaryData;
      let tertiaryData;
      let quickLinks;

      if (userContextResponse instanceof Error) {
        userContext = null;
      } else {
        userContext = userContextResponse;
      }
      if (categoriesResponse instanceof Error) {
        categories = [];
      } else {
        categories = categoriesResponse;
      }

      const isGuest = userContext.is_guest;
      const showHolidayData = experimentFlags.getFlag(HOLIDAY_SHOPS, userContext);

      // Secondary nav
      if (!disableSecondary) {
        if (isWeddingBoutiquePage) {
          secondaryData = isGuest ? getBoutiqueNavItems(userContext, categories) : [];
        } else {
          secondaryData = isGuest
            ? getRegistryNavItems(user, categories, showHolidayData)
            : getRegistrySecondaryNavItems(userContext);
        }
      }

      // Tertiary nav
      if (!isGuest && !disableSecondary) {
        if (isWeddingBoutiquePage) {
          tertiaryData = getBoutiqueNavItems(userContext, categories);
        } else {
          const registrySlug = userContext?.has_registry
            ? userContext?.user_role_account_weddings?.[0].slug
            : undefined;
          quickLinks = getRegistryQuickLinks(registrySlug);
          tertiaryData = getRegistryNavItems(user, categories, showHolidayData);
        }
      }

      window.dispatchEvent(
        new CustomEvent('INIT_NAV_DATA', {
          detail: {
            secondaryData,
            userContext,
            showPromo,
            activePrimaryLink,
            activeSecondaryLink,
            activeTertiaryLink,
            disablePrimaryNavCollapse,
            tertiaryData,
            quickLinks,
          },
        })
      );

      publishFeatureFlagOverrides(FLAGS, FeatureFlagsProductType.WEB_REGISTRY);

      return [userContextResponse, userContextResponse];
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log('Error fetching nav data', e);
    });
};

export default initNavData;
