type ShippingRequestAttributes = {
  isExpedited: boolean;
  isRushed: boolean;
};
export const setShippingRequestAttributes = (
  isExpedited: boolean,
  isRushed: boolean
): ShippingRequestAttributes => {
  // BE considers the rushed option being an combination of the expedited option + 1 day rush processing
  // If the rushed option is true, we must send the expedited value being the same.
  return {
    isExpedited: isRushed || isExpedited,
    isRushed,
  };
};
