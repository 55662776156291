import getEnvironment from '@zola-helpers/client/dist/es/util/environment';
import { createEnvironmentFlags, getFlag } from '@zola-helpers/client/dist/es/featureFlags';

export const FLAGS = {
  datadog: createEnvironmentFlags(false, true, true), // Toggle datadog monitoring
  giftImportBrandExtraction: createEnvironmentFlags(true, true, false),
  pushSignupIntoCustomization: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-980
  debugPaymentIntents: createEnvironmentFlags(false, false, false),
};

const get = (flag: keyof typeof FLAGS): boolean => getFlag(FLAGS, flag);

export default {
  get,
  getEnvironment,
};
