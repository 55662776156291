import type { WProductLookView, WSkuPreview } from '@zola/svc-web-api-ts-client';
import { formatPrice, formatZolaPriceRange } from './currencyFormatter';

const PRICING_TYPES = {
  msrp: 'MSRP',
  sale: 'COMPAREATPRICE',
  discount: 'DISCOUNTPRICE',
};

export type ProductPrices = {
  zolaPrice?: string;
  msrp?: string;
  sale?: string;
  discount?: string;
};

export const isProductFree = (activeProductLook?: WProductLookView, activeSku?: WSkuPreview) => {
  const isProductLookFree =
    activeProductLook?.price_range?.min_price === 0 &&
    activeProductLook?.price_range?.max_price === 0;
  const isSkuFree = activeSku?.price_cents === 0;
  return isProductLookFree || isSkuFree;
};

export const getProductPrices = (
  activeProductLook: WProductLookView,
  activeSku?: WSkuPreview
): ProductPrices => {
  const productPrices: ProductPrices = {};
  const currentPricingType = activeSku
    ? activeSku.reference_price?.type
    : activeProductLook.reference_price_range?.type;
  const formattedPrice = activeSku
    ? (formatPrice(activeSku.reference_price?.amount) as string)
    : formatZolaPriceRange(
        activeProductLook.reference_price_range || activeProductLook.price_range
      );
  let priceCopy = '';

  if (currentPricingType === PRICING_TYPES.msrp) {
    productPrices.msrp = formattedPrice;
    priceCopy = 'Our Price';
  } else if (currentPricingType === PRICING_TYPES.sale) {
    productPrices.sale = formattedPrice;
    priceCopy = 'Sale';
  } else if (currentPricingType === PRICING_TYPES.discount) {
    productPrices.discount = formattedPrice;
  }
  productPrices.zolaPrice = activeSku
    ? `${priceCopy} ${formatPrice(activeSku.price_cents)}`
    : `${priceCopy} ${formatZolaPriceRange(activeProductLook.price_range)}`;

  // special free pricing for invites sampler pack
  if (isProductFree(activeProductLook, activeSku)) {
    productPrices.zolaPrice = 'Free';
  }
  return productPrices;
};
